* {
  margin: 0;
  padding: 0;
  text-indent: 0;
}
h1 {
  color: black;
  /* font-family: "Times New Roman", serif; */
  /* font-style: normal; */
  /* font-weight: bold; */
  text-decoration: none;
  font-size: 15pt;
}
p {
  color: black;
  /* font-family: "Times New Roman", serif; */
  /* font-style: normal; */
  /* font-weight: normal; */
  text-decoration: none;
  font-size: 12.5pt;
  margin: 0pt;
}
.a,
a {
  color: black;
  /* font-family: "Times New Roman", serif; */
  /* font-style: normal; */
  /* font-weight: normal; */
  text-decoration: none;
  font-size: 12.5pt;
}
h2 {
  color: black;
  /* font-family: "Times New Roman", serif; */
  /* font-style: normal; */
  /* font-weight: bold; */
  text-decoration: none;
  font-size: 12.5pt;
}
li {
  display: block;
}
#l1 {
  padding-left: 0pt;
}
#l1 > li > *:first-child:before {
  content: "- ";
  color: black;
  /* font-family: "Times New Roman", serif; */
  /* font-style: normal; */
  /* font-weight: normal; */
  text-decoration: none;
  font-size: 12.5pt;
}
#l2 {
  padding-left: 0pt;
}
#l2 > li > *:first-child:before {
  content: "• ";
  color: black;
  /* font-family: "Times New Roman", serif; */
  /* font-style: normal; */
  /* font-weight: normal; */
  text-decoration: none;
  font-size: 12.5pt;
}
li {
  display: block;
}
#l3 {
  padding-left: 0pt;
}
#l3 > li > *:first-child:before {
  content: "- ";
  color: black;
  /* font-family: "Times New Roman", serif; */
  /* font-style: normal; */
  /* font-weight: normal; */
  text-decoration: none;
  font-size: 12.5pt;
}
