@media (max-width: 1200px){
    .place_product_img .feature_img {width: 20%;}
    .place_product_img .feature_img img{max-width:100%;}
    .video_demo .trail_post_img {height: 210px;}
    .about_thruhike .trail_post_img{transform: scale(1.15);margin: 24px 0;}
}
@media (max-width: 1152px){

    /*------------------------ new page css ------------------*/
    .about_thruhike .coming_soon_app img{padding: 0px 10px;}
    .coming_soon_app h3 {font-size: 30px;}
    .about_thruhike > div {width: 100%;}
}
@media (max-width: 1028px){

    /*------------------------ new page css ------------------*/
    .inside_slider h1{font-size: 55px;line-height: 60px;margin-bottom: 40px;}
    .inside_slider p{font-size: 25px;line-height: 30px;margin-bottom: 30px;}
    .inside_slider {height: 550px;}
    .feature_place_product h2{font-size: 45px;margin-bottom: 30px;}
    .about_thruhike {padding: 60px 0px;}
    .about_thruhike h3{margin-bottom: 10px;font-size: 35px;}
    .about_thruhike p{font-size: 18px;}
    .coming_soon_app h3 {font-size: 25px;}
    .schedule_demo h2{font-size: 50px;line-height: 60px;}
    .schedule_demo p{font-size: 18px;}
    .customers_amplify{width: 560px;}
    .customer_detail h3{font-size: 30px;}
    .customer_detail p{font-size: 18px;}
    .video_demo .trail_post_img {height: 180px;}

    .trail_width_responsive {width: 450px;}
    .about_thruhike .trail_post {height: 460px;}

}
@media (max-width: 980px){

    /*------------------------ new page css ------------------*/
    /* .about_thruhike .trail_post_img{width: 95%;height: 255px;} */
    .tab-categories-wrapper .category-tab{font-size: 16px;}
    .schedule_demo .trail_post_img {height: 170px;}
    .schedule_demo h2 {font-size: 45px;line-height: 60px;}
    .schedule_demo {padding-top: 30px;}
    
}
@media (max-width: 820px){

    /*------------------------ new page css ------------------*/
    .inside_slider h1 {font-size: 45px;line-height: 50px;margin-bottom: 30px;letter-spacing: 0px; }
    .inside_slider p {font-size: 21px;line-height: 30px;margin-bottom: 30px;}
    .inside_slider .serach_bar {width: 100%;}
    /* .about_thruhike .trail_post_img {width: 95%;height: 200px;} */
    .about_thruhike h3 {font-size: 30px;}
    .feature_place_product h2 {font-size: 35px;}
    .footer_menu li a{font-size: 13px;padding: 0 5px;}
    .schedule_demo h2 {font-size: 38px;line-height: 50px;}
    .bg_line_img {background-image: none;}
    .schedule_demo .trail_post_img {height: 135px;}
    .about_thruhike > div > .col-lg-6 {max-width: 100%; flex: 0 0 100%;}

}
@media (max-width: 767px){

    /*------------------------ new page css ------------------*/
    .about_thruhike button.save {margin-bottom: 30px;}
    .explore_fetures .about_thruhike img{margin-bottom: 0px;}
    .inside_slider {height: 450px;}
    .schedule_demo .stroke_img{display: none;}
    .about_thruhike.why_thruhike{padding: 50px 0px 50px;}
    .talk_to_hike.about_thruhike{padding: 50px 0px;}
    .talk_to_hike .col-md-6{padding: 30px 20px; border: none;}
    .video_demo {display: flex; flex-direction: column; align-items: center;}
    .schedule_demo .trail_post_img {height: auto;}
    .about_thruhike > div .trail_width_responsive { margin: 0 auto;}
    
}

@media (max-width: 667px){

    /*------------------------ new page css ------------------*/
    .about_thruhike .coming_soon_app{padding: 40px 40px;}
    .about_thruhike .coming_soon_app h3{margin-bottom: 30px !important;}
    .footer .d-flex{display: block !important;text-align: center;}    
    .footer_menu:first-of-type{float: left;margin-top: 6px !important;}
    .footer_menu:last-child{float: right;margin-top: 6px !important;}
    .curated_content {text-align: left;}
    .place_product_img {display: inline-table;}
    .place_product_img .feature_img {margin: 0px;width: 50%;display: inline-block;}
    .place_product_img .feature_img img{width: 100%;}
    .customers_amplify{width: 90%;margin: 20px auto 20px;padding: 15px;}
    .video_demo .col-sm-6 {width: 70%;}
    
}
@media (max-width: 600px){

    /*------------------------ new page css ------------------*/
    .schedule_demo h2 {font-size: 40px;line-height: 50px;padding: 0px;}
    .slider_carouser .search_box > .d-flex {flex-direction: column;}
    .inside_slider .navbar-nav {display: flex;width: 100%;}
    .inside_slider .search_box{width: 100%;}
    .inside_slider .search_box .img_shape {display: none;}
    .inside_slider .search_box .form-control{background: #f5f5f5;margin: 0px !important;width: 100%;border-radius: 12px;height: 50px;}
    .inside_slider .search_box button{width: 100%;margin-top: 15px;}
    .inside_slider .navbar-nav .nav-item .nav-link {width: 100%;}
    .inside_slider .serach_bar {display: block;}

}
@media (max-width: 568px){
    
    /*------------------------ new page css ------------------*/
    .header_top_text {display: flex;align-items: center;justify-content: center;margin-top: 10px;}
    .header_top_text .click_here_btn{padding: 5px 10px; margin-left: 20px;}
    .green_btn_account{padding: 7px 15px;font-size: 15px;}
    .inside_slider h1 {font-size: 40px;line-height: normal;margin-bottom: 25px;}
    .inside_slider p {font-size: 18px;line-height: inherit;}
    .inside_slider {height: 460px;padding: 20px 0px;}
    .inside_slider .navbar-nav {display: flex;width: 100%;}
    .inside_slider .search_box{width: 100%;}
    .inside_slider .search_box .img_shape {display: none;}
    .feature_place_product h2 {font-size: 25px;}
    .video_demo .col-sm-6 {width: 100%;}
    .schedule_demo .trail_post_img {height: auto;}
    .customers_amplify{border-radius: 20px;}
    .slider_carouser ul.navbar-nav li.dropdown a {position: relative;border-radius: 12px;margin-bottom: 15px;}
    .slider_carouser ul.navbar-nav li.dropdown .dropdown-toggle::after {position: absolute; right: 10px;top: 20px;}
    .slider_carouser .search_box > .d-flex {flex-direction: column;}
    .inside_slider .search_box .form-control{background: #f5f5f5;margin: 0px !important;width: 100%;border-radius: 12px;height: 50px;}
    .inside_slider .search_box button{width: 100%;margin-top: 15px;}
    .inside_slider .navbar-nav .nav-item .nav-link {overflow: hidden;padding-left: 0px;}
    .about_thruhike .post_detail p {height: 40px;}
    .responsive_login {display: flex; justify-content: space-between;}
    .responsive_login .login_btn {
        width: 35%;
        padding: 7px 15px;
        font-size: 15px;
        color: #FAFAFC !important;
        background: #008B2A;
        border-radius: 50px;
        text-align: center;
        margin-left: 0px;
    }
    .responsive_login .green_btn_account {
        width: 35%;
        padding: 7px 15px;
        font-size: 15px;
        color: #FAFAFC !important;
        background: #008B2A;
        border-radius: 50px;
        text-align: center;
        margin-left: 0px;
    }

}
@media (max-width: 536px){

    /*------------------------ new page css ------------------*/
    .about_thruhike h3 {font-size: 25px;}
    .about_thruhike .coming_soon_app {padding: 40px 20px;}

}
@media (max-width: 480px){

    /*------------------------ new page css ------------------*/
    .inside_slider h1 {font-size: 30px;line-height: normal;margin-bottom: 25px;}
    .inside_slider p {font-size: 15px;line-height: inherit;}
    .inside_slider .search_box button{font-size: 16px;padding: 10px 14px;}
    .inside_slider .search_box .form-control{padding: 5px 10px;}
    .inside_customer{display: block;}
    .customer_detail{margin-left: 0px; margin-top: 10px;text-align: center;}
    .talk_to_hike.about_thruhike {padding: 0px;}
    .schedule_demo h2 {font-size: 30px;line-height: 40px;padding: 0px;}

}
@media (max-width: 414px){

    /*------------------------ new page css ------------------*/
    .tab-categories-wrapper .category-tab {font-size: 14px;line-height: normal;}
    .about_thruhike {padding: 40px 0px;}
    .inside_slider p {font-size: 14px; text-align: center;}
    .serach_tag .tag_serch {margin: 8px 3px;font-size: 13px;padding: 6px 7px;}
    .schedule_demo .trail_post_img {height: auto;}
    .about_thruhike .trail_post {height: auto;}
    .responsive_login .green_btn_account {width: 45%;}


}
@media (max-width: 393px){
    /*------------------------ new page css ------------------*/
    .header_top_text, .header_top_text .click_here_btn{font-size: 15px;}


}
@media (max-width: 360px){

    /*------------------------ new page css ------------------*/
    .inside_slider p {font-size: 17px;margin-bottom: 10px;}
    .about_thruhike h3 {font-size: 20px;}
    .responsive_login .green_btn_account {width: 55%;}
    
}