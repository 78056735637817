.payment-card {
  background: #f0f3f2;
  box-shadow: 0px 4px 70px rgba(127, 135, 158, 0.05);
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5px 0px;
}
.card-icon {
  background: #ffffff;
  border-radius: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 13px;
  margin: 14px 15px;
}

.card-info {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}

.card-delete-icon {
  padding: 32px 20px;
}
