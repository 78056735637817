

/*------------------------ new page css ------------------*/
.main_content.single_home{
    padding: 0px;
    margin-bottom: 45px;
}
.header_top_text{
    font-weight: 500;
    font-size: 18px;
    color: #1D2327;
    margin: 0 auto;
    text-transform: capitalize;
}
.header_top_text a{color: #1D2327;}
.header_top_text .click_here_btn{
    font-weight: 500;
    font-size: 18px;
    text-transform: capitalize;
    color: #008B2A;
    padding: 10px;
    border: 1px solid #008B2A;
    border-radius: 5px;
}
.header_top_text .click_here_btn:hover{
    color: #FAFAFC !important;
    background: #008B2A;
}
.green_btn_account{
    color: #FAFAFC !important;
    background: #008B2A;
    padding: 12px 15px;
    font-size: 18px;
    border-radius: 50px;
    text-align: center;
    margin-left: 40px;
}
.green_btn_account:hover{
    background: #047827 !important;
}
.slider_carouser{
    background: url(../images/slider_bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.inside_slider{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 650px;
    justify-content: start;
    padding: 50px 0px;
}
.inside_slider h1{
    font-weight: 700;
    font-size: 70px;
    line-height: 80px;
    text-align: center;
    margin-bottom: 50px;
    letter-spacing: 0.03em;
    color: #008B2A;
}
.inside_slider p{
    color: #231F20;
    font-weight: 500;
    font-size: 25px;
    line-height: 40px;
    margin-bottom: 50px;
    text-align: center;
}
.inside_slider .navbar-nav{display: inline-block;}
.inside_slider .navbar-nav .nav-item  .nav-link{min-width: 230px;}
.inside_slider .serach_bar{
    display: flex;
    width: 850px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 12px rgba(193, 193, 193, 0.17);
    border-radius: 12px;
    padding: 10px 15px;
}
.inside_slider .search_box{
    display: inline-block;
    width: calc(100% - 230px);
}
.inside_slider .search_box .form-control{
    background: transparent;
    padding: 14px 20px;
    border-radius: 0px;
}
.inside_slider .search_box .img_shape {
    height: 55px;
    position: absolute;
}
.inside_slider .search_box button{
    position: relative;
    right: auto;
    left: auto;
    top: auto;
    background: #008B2A;
    border-radius: 12px;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    padding: 13px 30px;
    white-space: nowrap;
}
.inside_slider .search_box button:hover{
    background: #047827 !important;
}
.serach_tag .tag_serch{
    padding: 8px 12px;
    color: #231F20;
    font-size: 15px;
    font-weight: 500;
    border-radius: 4px;
    margin: 10px 7px;
    display: inline-block;
    backdrop-filter: blur(25px);
    background: rgba(255, 255, 255, 0.7);
}
.serach_tag .tag_serch img{
    width: 17px;
    margin-right: 10px;
    vertical-align: sub;
}

.feature_place_product {
    background: #ffffff;
    padding: 50px 0px;
    text-align: center;
}
.feature_place_product h2{
    font-weight: 500;
    font-size: 60px;
    margin-bottom: 40px;
    color: rgba(35, 31, 32, 0.2);
}
.place_product_img{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.place_product_img .feature_img{
    margin: 0 15px;
}

.about_thruhike{
    padding: 90px 0px;    
}
.about_thruhike > div{width: 85%;margin: 0 auto;}
.about_thruhike h3{
    font-weight: 600;
    font-size: 45px;
    color: #231F20;
    margin-bottom: 20px;
}
.about_thruhike p{
    font-weight: 500;
    font-size: 20px;
    color: #374149;
}
.about_thruhike p.green_color{
    font-weight: 600;
    font-size: 24px;
    color: #008B2A;
    margin-bottom: 15px;
}
.about_thruhike button.save{
    width: auto;
    margin-top: 30px;
    padding: 12px 40px;
}
.about_thruhike .btn-primary.save {
    background: #008B2A;
    border-radius: 10px;
    color: #FFFFFF;
    width: auto;
    border: none;
    padding: 12px 40px;
    font-size: 18px;
    letter-spacing: 0.5px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.about_thruhike img{
    max-width: 100%;
}
/* .about_thruhike .trail_post_img{
    width: 482px;
    height: 270px;
    position: absolute;
    left: 12px;
    right: auto;
    top: 10%;
} */
.explore_fetures {
    background: #ffffff;
}
.tab-categories-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #B3B3B3;
    margin-bottom: 20px;
    margin-top: 30px;
}
.tab-categories-wrapper .category-tab{
    font-weight: 500;
    font-size: 18px;
    color: #66737F;
    text-align: center;
    width: 134px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.tab-categories-wrapper .category-tab.activated{
    font-weight: 600;
    color: #000000;
    border-bottom: 2px solid #008B2A;
}
.curated_content{
    display: flex;
    align-items: center;
    justify-content: start;
    border-radius: 20px;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #ffffff;
    padding: 20px 24px;
    margin-bottom: 20px;
    background: linear-gradient(180deg, #47DC79 0%, #1EA54B 100%);
}
/* .curated_content:hover{
    background: #1EA54B;
} */
.curated_content p {
    margin-left: 20px; margin-bottom: 0px;color: #ffffff;
}
.about_thruhike .coming_soon_app{
    width: 100%;
    padding: 60px 40px;
    background-color: #B8CEBA;
    box-shadow: 0px 10px 20px rgba(56, 153, 72, 0.07);
    border-radius: 40px;
    background-image: url(../images/Map.png);
}
.coming_soon_app h3{
    font-weight: 600;
    font-size: 38px;
}


.schedule_demo {
    background: linear-gradient(0deg, #E7FFEE, #E7FFEE), linear-gradient(180deg, #47DC79 0%, #1EA54B 100%);
    padding: 70px 0px 50px;
}

.schedule_demo h2{
    font-weight: 800;
    font-size: 50px;
    line-height: 80px;
    letter-spacing: 0.03em;
    color: #231F20;
    padding-right: 10%;
    margin-top: 20px;
}
.schedule_demo h2 u {
    text-decoration-color: #9A9A9A;
}
.schedule_demo .stroke_img{
    position: absolute;
    left: -40px;
    height: 100%;
}
.schedule_demo p{
    font-weight: 500;
    font-size: 22px;
    color: #231F20;
    margin-bottom: 0px;
    padding-top: 20px;
}
.schedule_demo .trail_post_img img.video_play{
    width: 55px;
    height: 55px;
}
.about_thruhike.why_thruhike{
    padding: 50px 0px 50px;
    background: #ffffff;
}

.customers_amplify{
    background: #F9F9F9;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    width: 650px;
    padding: 30px;
    margin-bottom: 50px;
}
.inside_customer{
    display: flex;
    align-items: start;
}
.customer_detail {margin-left: 30px;text-align: left;}
.customer_detail h3{
    font-weight: 600;
    font-size: 30px;
    color: #008B2A;
} 
.customer_detail p{
    font-weight: 500;
    font-size: 18px;
    color: #374149;
}
.bg_line_img {
    width: 100% !important;
    background-image: url(../images/bg_line.png);
    background-repeat: no-repeat;
    background-position: 10% 35%;
}
.talk_to_hike.about_thruhike{
    background: linear-gradient(0deg, #E7FFEE, #E7FFEE), linear-gradient(180deg, #47DC79 0%, #1EA54B 100%);
    padding: 70px 0px;
    text-align: center;
}
.talk_to_hike .col-md-6{
    border-right: 2px dashed #231F20;
    padding: 50px 20px;
}
.talk_to_hike .col-md-6:last-child{border: none;}
.feature_slider .owl-nav button span{font-size: 14px;}
.video_demo .trail_post_img{
    height: 245px;
}


.about_thruhike .trail_post{
    padding: 10px;
    float: left;
    width: 100%;
    overflow: visible;
    margin-bottom: 20px;
    border-radius: 20px;
    background: linear-gradient(180deg, #47DC79 0%, #1EA54B 100%);
}
.about_thruhike .post_detail p{
    margin: 10px 0px;
    font-size: 14px;
    color: #FFFFFF;
}
.about_thruhike .trail_post_user p{
    float: left;
    width: calc(100% - 60px);
    color: #66737F;
    font-size: 14px;
    margin: 0px;
}
.about_thruhike .trail_post_user p a{ float: right;}
.about_thruhike .tag_list a{
    background: rgba(218, 245, 227, 0.8);
}
.about_thruhike .trail_post_img{
    transform: scale(1.2);
    margin: 30px 0;
}