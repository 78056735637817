@font-face {
  font-family: "Branding";
  src: url("./assets/css/fonts/BrandingMedium.eot");
  src: url("./assets/css/fonts/BrandingMedium.eot?#iefix") format("embedded-opentype"), url("./assets/css/fonts/BrandingMedium.woff") format("woff"), url("./assets/css/fonts/BrandingMedium.ttf") format("truetype");
  font-style: normal;
}

body {
  font-family: "Branding";
  background: #f0f3f2;
  font-size: 16px;
}

html {
  scroll-behavior: smooth;
}

.container {
  max-width: 1366px;
}

ul {
  padding: 0px;
  margin: 0px;
}
a,
a:hover {
  text-decoration: none;
}

:focus {
  outline: none !important;
}

img {
  vertical-align: bottom;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b8c1cc;
  opacity: 1;
  font-weight: 500;
  /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b8c1cc;
  font-weight: 500;
}
.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b8c1cc;
  font-weight: 500;
}

/*------------------------ top header ----------------------*/

/*------------------ Modal css -----------------*/
.modal-title {
  font-weight: 600;
  font-size: 22px;
  line-height: normal;
  color: #231f20;
}
.modal-header {
  border: none;
  padding-bottom: 0px;
  align-items: normal;
}
.modal .btn-close {
  background: #f2f2f7;
  color: #66737f;
  border-radius: 50px;
  opacity: 1;
  font-size: 17px;
  width: 15px;
  height: 15px;
  line-height: 20px;
  margin: 0;
  position: absolute;
  right: 15px;
}
.form_field .form-control {
  border: 1px solid #b8c1cc;
  border-radius: 10px;
  padding: 13px 15px;
  color: #231f20;
  font-weight: 600;
}
.form_field .form-control:focus {
  border: 1px solid #008b2a;
  box-shadow: none;
}
.form_field label {
  font-size: 14px;
  color: #11142d;
}
select.form-control {
  background: url(./assets/images/chevron-right.png);
  background-position: 97% 50%;
  background-repeat: no-repeat;
}
button.save {
  background: #008b2a;
  border-radius: 10px;
  color: #ffffff;
  width: 100%;
  border: none;
  padding: 12px 15px;
  font-size: 18px;
  letter-spacing: 0.5px;
}
button.save:hover,
button.save:active {
  background: #047827 !important;
}
button.save:disabled {
  pointer-events: none;
  opacity: 0.65;
  background: #008b2a;
}
.form_field .btn_copy {
  background: #eaeaea;
  border: 1px solid #e5e5e5;
  border-radius: 0px 10px 10px 0px;
  padding: 12px 25px;
  color: #008b2a;
}
.modal .rounded-circle:hover img {
  box-shadow: 0px 3px 5px rgb(0 0 0 / 50%);
  border-radius: 50px;
}

/*---------------------- navigation bar ----------------*/
.navbar {
  padding: 0px;
  background: #fff !important;
  box-shadow: 0px 1px 10px rgb(0 0 0 / 5%);
  height: 76px;
}
.navbar.fixed {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
}

.navbar-brand {
  padding: 0px;
}

ul.navbar-nav li.dropdown a {
  background: #ffffff;
  border-radius: 60px;
  padding: 14px 15px;
  color: #66737f;
}
ul.navbar-nav li.dropdown a i {
  color: #008b2a;
  margin-right: 10px;
}
.dropdown-toggle::after {
  content: "";
  border: none;
  background: url(./assets/images/chevron-right.png);
  width: 14px;
  height: 8px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: baseline;
}

.search_box {
  position: relative;
}

.search_box .form-control {
  background: #e2ebe8;
  border-radius: 60px;
  padding: 14px 20px 14px 60px;
  font-size: 14px;
  line-height: 15px;
  height: 44px;
  border: none;
}
.search_box button {
  position: absolute;
  top: 7px;
  padding: 0px;
  border: none;
  left: 20px;
  background: none;
}
.profile_menu li a,
ul.profile_menu li.dropdown a {
  padding: 10px 15px !important;
  background: #e2ebe8;
  border-radius: 60px;
  margin-left: 20px;
  color: #231f20;
}

ul.profile_menu li .dropdown-toggle::after {
  margin-left: 15px;
}

.user_profile {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  display: inline-flex;
  vertical-align: bottom;
  overflow: hidden;
  margin-right: 10px;
}

.user_profile img {
  width: 100%;
}

ul.navbar-nav .dropdown-menu {
  padding: 0px;
  overflow: hidden;
  width: 240px;
  left: auto !important;
  right: 0px !important;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
  border-radius: 10px;
  border: none;
  margin-top: 7px;
}
ul.navbar-nav .dropdown-menu li .dropdown-divider {
  width: 85%;
  border-top: 1px solid #e7e9eb;
}
ul.navbar-nav .dropdown-menu li a {
  margin: 0px;
  border-radius: 0px;
  background: none;
  font-size: 16px;
  color: #231f20;
  padding: 10px 15px !important;
}

ul.navbar-nav .dropdown-menu li a.logout {
  color: #dd1010;
}

ul.navbar-nav .dropdown-menu li a:hover {
  color: #008b2a;
}

ul.navbar-nav .dropdown-menu li a svg {
  vertical-align: sub;
  margin-right: 10px;
}

ul.navbar-nav .dropdown-menu li a:hover svg path {
  fill: #008b2a;
}

ul.navbar-nav .dropdown-menu li h4 {
  font-weight: 600;
  font-size: 16px;
  color: #231f20;
}
.user_profiletop {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  overflow: hidden;
  margin-right: 15px;
}

.user_profiletop img {
  width: 100%;
}

ul.navbar-nav .dropdown-menu li a.profile_btn {
  background: #008b2a;
  border-radius: 5px;
  padding: 5px 10px !important;
  font-size: 14px;
  color: #ffffff;
  display: inline-block;
  text-decoration: none;
}
ul.navbar-nav .dropdown-menu.location_drop {
  width: 300px;
  padding: 10px;
  height: 65px;
}
.location_drop .form-control {
  background: #e2ebe8;
  border-radius: 6px;
  padding: 14px 14px;
  font-size: 16px;
  height: 44px;
  border: none;
}

/*----------------------- Footer css --------------------*/
.footer {
  background: #ffffff;
  border-top: 1px solid #d2d2d2;
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 12px 0;
  font-size: 14px;
  z-index: 1;
}
.footer_menu {
  padding: 0px;
  display: inline-block;
  list-style: none;
}

.footer_menu li {
  display: inline-block;
}

.footer_menu li a {
  color: #66737f;
  font-size: 14px;
  padding: 0 10px;
}

.footer_menu li a:hover {
  color: #008b2a;
}

/*------------------------ after header css ------------------*/
.main_content {
  padding: 20px 0px 30px;
  margin-bottom: 50px;
}
.hz_traillist {
  display: flex;
  width: 100%;
  align-items: center;
  background: #e2ebe8;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
}
.hz_traillist h5 {
  font-weight: 600;
  font-size: 16px;
  color: #231f20;
  margin: 0px;
}
.hz_traillist h5 i {
  position: absolute;
  right: 10px;
  color: #fe4954;
}

.hz_traillist:hover h5 {
  color: #008b2a;
}

.trail_mainimg {
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin-right: 20px;
}
.trail_mainimg img {
  width: 100%;
  height: 100%;
}
.multiple_img {
  width: 40px;
  height: 40px;
  overflow: hidden;
  float: left;
}
.multiple_img img {
  width: 100%;
  height: 100%;
}
.horizontal_line {
  border-top: 1px solid #9f9d9d;
  width: 98%;
  margin: 0px auto 20px auto;
}
h5.span.trailtitle {
  overflow: hidden;
  text-overflow: ellipsis;
}
.trail_post {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  float: left;
  width: 100%;
  margin-bottom: 20px;
  height: 480px;
  overflow: hidden;
  position: relative;
}

.trail_post_1 {
  height: 250px !important;
}
.trail_post_1 .trail_left h4 {
  height: 21px;
  overflow: hidden;
}
.trail_right .trail_tag_like .social_icon svg {
  margin-right: 0px;
}
.user_img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  overflow: hidden;
  margin-right: 10px;
  float: left;
}
.user_img img {
  width: 100%;
  height: 100%;
}
.trail_post_user {
  float: left;
  width: 100%;
}
.trail_post_user h4 {
  font-weight: 600;
  font-size: 16px;
  color: #231f20;
  float: left;
  width: calc(100% - 60px);
  margin-bottom: 0px;
}
.trail_post_user h4 a {
  color: #231f20;
  font-size: 18px;
}

.trail_post_user h4 a:hover {
  color: #008b2a;
}

.trail_post_user p {
  float: left;
  width: calc(100% - 60px);
  color: #66737f;
  font-size: 14px;
  margin: 0px;
}
.trail_post_user p a {
  float: left;
  color: #66737f;
  font-size: 14px;
  margin: 0px;
}
.trail_post_user p span {
  color: #231f20;
  font-weight: 500;
  font-size: 12px;
  margin-top: 5px;
}

.trail_post_user p span i {
  color: #008b2a;
}

.trail_post_img {
  float: left;
  width: 100%;
  height: 230px;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px 0px;
  position: relative;
}
.trail_post_img img {
  width: 100%;
  height: 100%;
}
.trail_post_img.video_icon:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 50%);
}
.trail_post_img img.video_play {
  position: absolute;
  width: auto;
  height: auto;
  left: 45%;
  top: 37%;
}
.post_detail .badge {
  background: #f0f3f2;
  border-radius: 5px;
  color: #008b2a;
  font-size: 14px;
  padding: 5px;
  font-weight: 500;
  letter-spacing: 0px;
}

.post_detail .badge:hover {
  background: #daf5e3;
}

.post_detail .trail_tag_like {
  float: right;
  display: flex;
}
.trail_tag_like .social_icon {
  background: #f0f3f2;
  border-radius: 8px;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  margin-left: 8px;
  position: relative;
}

.trail_tag_like .social_icon svg {
  vertical-align: sub;
  margin-right: 5px;
}

.trail_tag_like .social_icon i {
  font-size: 16px;
  color: rgb(254, 73, 84);
  width: 30px;
  height: 30px;
  padding-top: 8px;
}

.trail_tag_like .social_icon:hover {
  background: #daf5e3;
}

.favriout_hover {
  display: none;
}

.trail_tag_like .social_icon:hover .favriout_hover {
  background: #fff0f1;
  border-radius: 10px;
  font-size: 16px;
  color: #231f20;
  width: 300px;
  position: absolute;
  right: 0px;
  padding: 10px 15px;
  top: -5px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.trail_tag_like .social_icon .favriout_hover i,
.favriout_hover i {
  font-size: 16px;
  color: #fe4954;
  float: right;
  margin-top: 0px;
  padding: 0px;
}
.trail_tag_like .social_icon .favriout_hover i {
  width: auto;
  height: auto;
}
.trail_list_view .trail_tag_like .social_icon svg {
  margin: 0px;
  vertical-align: middle;
}
.post_detail p {
  margin: 10px 0px;
  font-size: 14px;
  color: #66737f;
}
.post_description {
  height: 40px;
  overflow: hidden;
}
.post_detail p.trail_tips {
  width: 100%;
  font-size: 14px;
  color: #374149;
  margin: 0px;
}

.post_detail p.trail_tips i {
  color: #008b2a;
}

.tag_list {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  height: 40px;
  scrollbar-color: #66737f #fff;
}
.tag_list a {
  margin-right: 5px;
  background: #f0f3f2;
  border-radius: 10px;
  color: #374149;
  font-size: 12px;
  padding: 5px;
}
.tag_list a:hover,
.tag_list a.active {
  background: #daf5e3 !important;
}

.tag_list::-webkit-scrollbar {
  height: 5px;
}

.tag_list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.tag_list::-webkit-scrollbar-thumb {
  background-color: #66737f;
  border-radius: 10px;
}
.tag_list.bottom_fixed {
  position: absolute;
  bottom: 12px;
  width: 94%;
}
.create_trail_btn {
  padding: 8px 20px;
  background: #ffffff;
  border: 1px solid #008b2a;
  border-radius: 105px;
  font-size: 16px;
  color: #008b2a;
  font-weight: 600;
  display: inline-block;
}
.create_trail_btn:hover {
  background: #008b2a;
  color: #ffffff;
}

.modal .hz_traillist {
  background: #ffffff;
}

.modal .trail_mainimg {
  border-radius: 6px;
  width: 60px;
  height: 60px;
}
.modal .multiple_img {
  width: 30px;
  height: 30px;
}
.model_title_header {
  font-weight: 600;
  font-size: 30px;
  color: #231f20;
  margin: 15px 0px;
}
.toggle_password {
  position: absolute;
  right: 12px;
  top: 8px;
  cursor: pointer;
}
.link_text a {
  color: #008b2a;
  font-weight: 600;
}

.link_text a:hover {
  text-decoration: underline;
}

.toggle_password.slash_icon:before {
  content: "/";
  position: absolute;
  right: 5px;
  font-size: 22px;
  top: -2px;
  color: #66737f;
}
.flag_css {
  background: white;
  border: 1px solid #b8c1cc;
  border-radius: 10px;
}
.profile_img {
  width: 112px;
  height: 112px;
  border-radius: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  position: relative;
  border: 1px dashed #b8c1cc;
  padding: 5px;
}
.profile_img img {
  width: 100%;
  height: 100%;
  border-radius: 50px;
}
.edit_userimage {
  width: 100%;
  height: 100%;
  /* position: absolute;
  top: 0;
  right: 0; */
}
.profile_img input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  border-radius: 50px;
  overflow: hidden;
  z-index: 0;
  opacity: 0;
}
.modal-title .text_popup {
  font-weight: 500;
  font-size: 16px;
  color: #66737f;
  margin-bottom: 20px;
}

.form_field .validationcode .form-control {
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  padding: 8px 8px;
}
.heading_title {
  font-weight: 600;
  color: #231f20;
  font-size: 20px;
  margin-bottom: 16px;
}

.heading_title.big_title {
  font-size: 24px;
}

.trailslider .hz_traillist {
  display: block;
  background: none;
  margin-bottom: 0px;
}

.trailslider hr {
  margin-bottom: 20px;
}

.trailslider .hz_traillist:hover h5 {
  color: #008b2a;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.trailslider .trail_mainimg {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 5px;
}
.trailslider .trail_mainimg .multiple_img {
  width: 100px;
  height: 100px;
}
.trailslider .hz_traillist h5 {
  font-weight: 600;
  font-size: 16px;
  color: #231f20;
  margin: 6px 0 20px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.owl-carousel .owl-dots,
.owl-carousel .owl-nav {
  display: block;
  color: #66737f;
}
.owl-carousel .owl-dots {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 5px;
  margin: 0px 10% -34px 0px;
}
.owl-carousel .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  background: #d2d2d2;
  border-radius: 50px;
  margin: 0 4px;
}

.owl-carousel .owl-dots .owl-dot.active {
  background: #008b2a;
}

.owl-carousel .owl-nav button.owl-next {
  float: right;
}

.inside_righttrail {
  /* position: fixed; */
  max-width: 430px;
  /* top: 215px; */
}
.inside_righttrail.fixed {
  top: 90px;
}

body.bg_white {
  background: #fff;
}
.heading_title a {
  color: #d73737;
  font-size: 18px;
  font-weight: 500;
  float: right;
  line-height: 25px;
}

.heading_title a:hover {
  color: #008b2a;
}

.trail_detail_list.trailslider .hz_traillist {
  background: #e2ebe8;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}
.trail_detail_list.trailslider .trail_mainimg {
  width: 180px;
  height: 180px;
  margin: 0 auto;
}
.trail_detail_list.trailslider .trail_mainimg .multiple_img {
  width: 90px;
  height: 90px;
}

.trail_detail_list.trailslider .hz_traillist h5 {
  margin-bottom: 0px;
}

.heading_title a.backarrow {
  color: #66737f;
  font-size: 18px;
  font-weight: 500;
  float: none;
  margin-right: 20px;
}
.trail_btn {
  text-align: right;
  margin-top: 50px;
}
.trail_btn .add_trail {
  font-size: 16px;
  color: #ffffff;
  background: #ee7a12;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 15%);
  border-radius: 60px;
  padding: 12px 15px;
}

.trail_btn .add_trail:hover {
  background: #ee933e;
}

.trail_btn .add_trail i {
  margin-right: 10px;
}

.position_fixed {
}

.heading_title a.backarrow.rename {
  font-size: 16px;
  color: #008b2a;
  line-height: initial;
  margin-left: 10px;
  margin-right: 0px;
  background: #f0f3f2;
  border-radius: 60px;
  padding: 10px;
}
.heading_title a.backarrow.rename:hover,
.heading_title a.backarrow.delete:hover {
  font-weight: 600;
}
.heading_title a.backarrow.delete {
  font-size: 16px;
  color: #dd1010;
  line-height: initial;
  margin-right: 0px;
  background: #f0f3f2;
  border-radius: 60px;
  padding: 10px;
}
.heading_title a.backarrow svg {
  margin-right: 10px;
  vertical-align: text-top;
}

p.traildesc {
  margin-bottom: 15px;
}

button.cancel {
  background: #ffffff;
  border-radius: 10px;
  color: #000;
  width: 100%;
  border: none;
  padding: 12px 15px;
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: 600;
}
button.cancel:hover,
button.cancel:active {
  background: #e2ebe8 !important;
  color: #000 !important;
}
button.delete {
  background: #dd1010;
  border-radius: 10px;
  color: #ffffff;
  width: 100%;
  border: none;
  padding: 12px 15px;
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: 600;
}
button.delete:hover,
button.delete:active {
  background: #c60909 !important;
}

.position_fixed {
  position: fixed;
  width: 100%;
  bottom: 70px;
  left: 0;
  margin: 0;
}

.location_icon {
  position: relative;
}

.location_icon img {
  position: absolute;
  right: 22px;
  top: 42px;
}

.add_new_field {
  position: relative;
}

.add_new_field button {
  border: none;
  padding: 0px;
  background: none;
  position: absolute;
  right: 22px;
  top: 12px;
}
.addvideo_photo {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-right: 20px;
  display: inline-block;
  margin-bottom: 20px;
}
.addvideo_photo img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.addvideo_photo img.delete_videoicon {
  position: absolute;
  right: -12px;
  top: -8px;
  width: auto;
  height: auto;
  border-radius: 0px;
}
.addvideo_photo .video_play {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 35px;
  top: 35px;
}

.delete_videoicon:hover {
  filter: grayscale(1);
}

.btn_cancel,
.btn_save {
  background: #008b2a;
  border-radius: 10px;
  padding: 12px 80px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  margin-top: 20px;
  margin-left: 20px;
  display: inline-block;
}
.btn_cancel {
  background: #ffffff;
  color: #000;
  margin-left: 0px;
}
.btn_save:hover {
  background: #047827 !important;
  color: #fff;
}
.btn_cancel:hover {
  background: #e5e5e5 !important;
  color: #000;
}

.accunt_delete_btn button.cancel {
  background: #008b2a;
  color: #fff;
}
.accunt_delete_btn button.cancel:hover {
  background: #047827 !important;
  color: #fff !important;
}
.accunt_delete_btn button.delete {
  background: #ffffff;
  color: #dd1010;
}
.accunt_delete_btn button.delete:hover {
  background: #dd1010;
  color: #ffffff;
}

.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 2px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background: #fff;
  border: 2px solid #231f20;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  z-index: 1000;
  vertical-align: sub;
}
.option-input:checked {
  background: #008b2a;
  background-image: none !important;
  border-color: #008b2a;
}
.option-input:checked::before {
  width: 20px;
  height: 20px;
  content: "\f00c";
  font-size: 12px;
  font-weight: 800;
  left: -2px;
  position: absolute;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: "Font Awesome 5 Free";
}
.option-input:checked::after {
  background: #008b2a;
  content: "";
  display: block;
  position: relative;
  z-index: 100;
}
.option-input.radio {
  border-radius: 50%;
  float: none;
}
.option-input.radio::after {
  border-radius: 50%;
}

.subscriptions_tab .nav-pills {
  background: rgba(118, 118, 128, 0.12);
  border-radius: 8px;
  padding: 4px;
}
.subscriptions_tab .nav-pills .nav-link {
  background: transparent;
  color: #000000;
  padding: 4px 0;
}

.subscriptions_tab .nav-pills .nav-link.active {
  background: #fb7800;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  border-radius: 7px;
  color: #fff;
  padding: 4px 0;
}
.subscriptions_tab .nav-pills .nav-item:focus-visible,
.subscriptions_tab .nav-pills .nav-link:focus-visible {
  outline: none;
}

.inside_tab {
  border-bottom: 1px solid #b8c1cc;
}

.inside_tab .nav-item {
  padding: 12px 0;
  font-weight: 600;
  color: #66737f;
  font-size: 16px;
}
.inside_tab .nav-item.active {
  color: #231f20;
  border-bottom: 2px solid #fb7800;
}
.subscription_plan {
  background: linear-gradient(180deg, #039f38 0%, #00802b 100%);
  border-radius: 20px;
  color: #fff;
  padding: 20px;
  min-height: 460px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.subscription_plan h4 {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 2px;
  color: #ffffff;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.subscription_plan ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  margin-bottom: 20px;
}
.subscription_plan ul li {
  font-weight: 500;
  font-size: 18px;
}
.subscription_plan .quater_selection {
  border: 1.5px dashed #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
}
.subscription_plan .quater_selection .popular_img {
  position: absolute;
  right: -2px;
  top: 10px;
  width: auto;
}
.subscription_plan .quater_selection h5 {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.subscription_plan .quater_selection h6 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 0px;
}
.subscription_plan .quater_selection h6 span {
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}

.subscription_plan .quater_selection.active {
  background: #ffffff;
}

.subscription_plan .quater_selection.active h5 {
  color: #b8c1cc;
}

.subscription_plan .quater_selection.active h6,
.subscription_plan .quater_selection.active h6 span {
  color: #231f20;
}

.subscription_plan .quater_selection.active h6 i {
  color: #029734;
}

.payment {
  text-align: center;
  margin: 20px 0px;
  display: inline-block;
  width: 100%;
}
.payment p {
  font-size: 16px;
  color: #029834;
}
.btn_save.btn_gery {
  background: #dfdfdf;
  color: #000;
  font-weight: 600;
}
.btn_save.btn_gery:hover {
  background: #e5e5e5 !important;
  color: #000;
}
.select_plan .quater_selection {
  cursor: pointer;
}

.trail_detail .trail_post_user h4 {
  font-weight: 700;
  font-size: 26px;
}

.trail_detail .trail_post_user p {
  width: 100%;
}

.trail_detail .trail_tag_like .social_icon {
  width: auto;
  height: auto;
  font-size: 16px;
  color: #231f20;
  background: #f0f0f0;
  border-radius: 60px;
  padding: 8px 12px;
}
.trail_detail .trail_tag_like .social_icon img {
  vertical-align: sub;
  margin-right: 8px;
}

.trail_detail .trail_tag_like .social_icon:hover {
  background: #daf5e3;
}

.detail_images {
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 1px solid #d2d2d2;
}

.detail_images .trail_post_img {
  height: 370px;
  margin: 0px;
}

.detail_images .trail_post_img img.video_play {
  left: 48%;
  top: 45%;
}
.detail_images .side_smallimg .trail_post_img {
  height: auto;
  margin-top: 0px;
}
.detail_images .tag_list a {
  font-size: 14px;
  letter-spacing: 0.02em;
}
.detail_images .video-js {
  border-radius: 12px;
  overflow: hidden;
}
.side_smallimg .trail_post_img.video_icon span {
  font-size: 16px;
  color: #fff;
  position: absolute;
  left: 30%;
  top: 43%;
}
.side_smallimg .trail_post_img.video_icon span img {
  width: auto;
  height: auto;
  margin-right: 10px;
}
.trail_tag_like .dropdown-toggle::after {
  display: none;
}

.rightside_detail .btn_save {
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 20px;
  padding: 12px 10px;
}
.rightside_detail .btn_save.btn_outline {
  border: 1px solid #008b2a;
  background: #fff;
  margin: 0px;
  color: #008b2a;
}
.rightside_detail .btn_save.btn_outline:hover {
  background: #008b2a;
  color: #fff;
}
.trail_description .user_img {
  width: 50px;
  height: 50px;
  margin-right: 25px;
}

.trail_description .trail_post_user {
  float: none;
}

.trail_description .trail_post_user h4 {
  width: calc(100% - 90px);
  font-size: 20px;
}
.trail_description .trail_post_user ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.trail_description .trail_post_user ul li {
  display: inline-block;
  margin: 5px 0;
}
.trail_description .trail_post_user ul li a {
  font-size: 18px;
  margin-right: 25px;
  color: #2a1483;
  text-decoration: underline;
}

.trail_description .trail_post_user ul li a:hover {
  color: #008b2a;
}

.trail_description .trail_post_user ul li a svg {
  vertical-align: text-top;
  margin-right: 10px;
}

.trail_description .alert {
  color: #000;
}

.trail_description .alert h4 {
  font-weight: 600;
}

.featuredon h5 {
  background: #f0f3f2;
  border-radius: 10px;
  padding: 16px 20px;
  font-weight: 500;
  font-size: 16px;
  color: #374149;
  margin-bottom: 10px;
}
.featuredon h5 p {
  margin-bottom: 0px;
  display: inline-block;
  margin-left: 10px;
  width: 93%;
}

.featuredon h5 img {
  vertical-align: top;
}

.spotted_trails .hz_traillist {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
}

.all_images .column {
  flex: 50%;
  max-width: 50%;
}
.all_images .column img {
  margin-bottom: 20px;
  vertical-align: middle;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
}

/* Data integrate developer classes */
.single_img img {
  /* height: 180px !important; */
}

.trail_mainimg {
  display: flex;
  flex-wrap: wrap;
}

.selectedClass {
  margin-bottom: 3px;
}
.tagClass {
  background-color: rgba(0, 0, 0, 0.15);
  padding: 3px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}
.removeClass {
  background: transparent;
  border: none;
}

.vjs-poster {
  background-size: cover !important;
  background-position: top center !important;
}

.bg-warning {
  background-color: #fde5cd !important;
  color: #d77b2e !important;
}
.bg-danger {
  background-color: #ffcfcb !important;
  color: #bc1d17 !important;
}

.owl-theme .owl-dots .owl-dot span {
  display: none !important;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 20px !important;
}

.subscription_header {
  margin-bottom: 35px;
}
.subscription_header .subscription_title {
  font-size: 26px;
  font-weight: 600;
}

.subscription_header .subscription_subtitle {
  font-size: 20px;
  font-weight: 300;
}

.loader-container {
  height: 100% !important;
  width: 100% !important;
  text-align: center;
  position: absolute;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.country_selectinput {
  width: 100%;
}
.country_selectinput button {
  font-size: 16px;
  width: 100%;
  border: 1px solid #b8c1cc;
  border-radius: 10px;
  padding: 9px 7px;
  color: #231f20;
  font-weight: 600;
}
.error_video {
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  position: absolute;
  background: #ffc3c3b8;
  width: 100%;
  z-index: 1;
  justify-content: center;
  text-align: center;
}
.error_video p {
  width: 100%;
  font-size: 21px;
  color: red;
  padding: 10px 0;
  font-weight: 600;
}

.tagClass {
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
  background: #daf5e3;
  border-radius: 5px;
  color: #008b2a;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  border: none;
  padding: 3px 8px;
}
.react-tags-wrapper .tag-wrapper {
  display: inline-flex;
  align-items: center;
}
.tagClass button.removeClass {
  margin-left: 10px;
  background: #008b2a;
  font-weight: 700;
  color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  line-height: normal;
  font-size: 13px;
}
.link-popup {
  color: #008b2a;
}

.header_trail_icon {
  display: flex;
  align-items: center;
  height: 44px;
}

.about_us_content {
  padding: 40px 0px;
}

.about_us_content h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  color: #231f20;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.about_us_content p {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 20px;
}
.about_us_content .img_position1 {
  width: 300px;
  height: 350px;
  border-radius: 20px;
}
.about_us_content .img_position2 {
  width: 300px;
  height: 350px;
  border-radius: 20px;
  margin-top: -30px;
}
.trail_heading_middle {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.by_user {
  font-size: 15px;
  color: #66737f;
  margin-left: 15px;
  font-weight: 500;
}
.hz_traillist .by_user {
  margin-left: 0px;
  font-size: 13px;
}
/*by devloper*/
/* .div_subscription_plan {
  min-height: 609px !important;
} */
.custom-video-tag video-js {
  max-width: 170px !important;
  width: 170px !important;
  height: 100px !important;
  border-radius: 10px !important;
}

.map-container {
  width: "100%";
  height: "800px";
}

/*--------------------- trail list page design css ----------------*/
.trail_list_view {
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
}
.trail_list_view .trail_top_header .left_img {
  width: 88px;
  height: 88px;
  margin-right: 20px;
  border-radius: 50px;
}

.trail_top_header .trail_list_inner {
  width: calc(100% - 110px);
}

.trail_list_inner .heading_title span {
  color: #66737f;
  font-size: 14px;
  font-weight: 500;
}
.trail_list_inner .post_detail .badge {
  line-height: 20px;
  vertical-align: middle;
}
.trail_list_inner .tag_list a {
  font-size: 14px;
  border-radius: 5px;
  margin-right: 7px;
}
.trail_list_inner .tag_list a img {
  vertical-align: text-top;
  margin: 0 4px;
  height: 14px;
}
.trail_list_inner .tag_list {
  align-items: center;
  display: flex;
}
.trail_list_inner .btn_save {
  margin: 0px;
  padding: 6px 15px;
  border-radius: 5px;
  width: 180px;
}
/* 
.button_create {
  margin-bottom: 20px;
} */

.button_create .btn_save.btn_blue {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #166ef3;
  background: #4a92ff;
  margin: 0px;
  margin-right: 12px;
}
.button_create .btn_save.btn_blue:hover {
  background: #166ef3 !important;
  border: 1px solid #166ef3;
}
.button_create .btn_save.btn_outline {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #008b2a;
  background: #fff;
  margin: 0px;
  color: #008b2a;
}
.button_create .btn_save.btn_outline:hover {
  background: #008b2a;
  color: #fff;
}

.button_create i {
  margin-right: 10px;
}

.inner_trail_list .trail_post {
  padding: 20px;
  display: flex;
  margin-left: 30px;
  width: calc(100% - 30px);
}

.inner_trail_list .drag_icon {
  position: relative;
  display: inline-block;
  width: 100%;
}

.inner_trail_list .drag_icon::before {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0px;
  top: 45%;
  cursor: grab;
  background-image: url(./assets/images/drag_icon.png);
  background-repeat: no-repeat;
}
.inner_trail_list .trail_post.trail_post_1 {
  margin-top: 10px;
  margin-bottom: 10px;
  background: #fff;
}
.inner_trail_list .trail_left {
  width: 280px;
}

.inner_trail_list .trail_left .trail_post_img {
  height: 160px;
}

.trail_right {
  padding-left: 25px;
  width: calc(100% - 280px);
}
.trail_right .location_name {
  margin-top: 18px;
  display: inline-block;
}
.trail_right .location_name span {
  color: #231f20;
  font-weight: 500;
  font-size: 14px;
}
.trail_right .post_detail .badge {
  line-height: 20px;
  vertical-align: middle;
}

.trail_right .location_name span i {
  color: #008b2a;
}

.trail_right .post_description {
  color: #66737f;
  font-size: 16px;
  font-weight: 600;
  height: 54px;
}
.trail_right .post_detail p.trail_tips {
  font-size: 15px;
  margin-bottom: 5px;
}
.trail_title_header {
  border-radius: 5px;
  padding: 15px 20px;
  margin-bottom: 20px;
  background: #4a92ff;
  display: flex;
  justify-content: space-between;
}
.trail_title_header h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
}
.trail_title_header .edit_delete_title a {
  color: #fff;
  margin-left: 20px;
}
.trail_title_header .edit_delete_title a:hover {
  color: #000;
}

@media (max-width: 1028px) {
  .inner_trail_list .trail_width_responsive {
    width: calc(100% - 30px);
  }
}
@media (max-width: 980px) {
  .book_list_div {
    flex-direction: column;
  }

  .trail_right .trail_list_inner {
    flex-direction: column;
  }

  .trail_list_inner .btn_save {
    margin-top: 10px;
  }

  .trail_right .location_name {
    margin-top: 10px;
  }
}
@media (max-width: 820px) {
  .trail_right .post_description {
    height: 95px;
    width: 100%;
  }
  .trail_right .location_name {
    width: 100%;
  }
  .post_detail .trail_tag_like {
    float: right;
    display: block;
    width: 100%;
    text-align: right;
  }
}
@media (max-width: 736px) {
  .trail_right .location_name {
    margin-top: 0px;
  }
}
@media (max-width: 667px) {
  .trail_right {
    padding-left: 0px;
    width: 100%;
  }

  .trail_right .post_description {
    height: 70px;
  }

  .trail_right .location_name {
    margin-top: 10px;
  }

  .inner_trail_list .trail_post {
    flex-direction: column;
  }

  .trail_right .post_detail p.trail_tips {
    margin-bottom: 15px;
  }

  .trail_top_header .trail_list_inner .justify-content-between {
    flex-direction: column;
  }
  .trail_post_1 {
    height: auto !important;
  }
  .trail_post_1 .tag_list.bottom_fixed {
    position: relative;
  }
}
@media (max-width: 480px) {
  .trail_top_header {
    flex-direction: column;
  }
  .trail_top_header .trail_list_inner {
    width: 100%;
    margin-top: 15px;
  }
  .trail_right .location_name {
    width: 100%;
    display: block;
  }
  .trail_right .post_description {
    width: 100%;
    display: inline-block;
  }

  .trail_title_header {
    padding: 10px 10px;
  }

  .trail_title_header h4 {
    font-size: 16px;
  }

  .trail_right .post_description {
    font-size: 14px;
    height: 85px;
  }
}
@media (max-width: 375px) {
  .button_create i {
    margin-right: 5px;
  }
  .button_create .btn_save.btn_blue,
  .button_create .btn_save.btn_outline {
    padding: 10px 10px;
    font-size: 14px;
    margin-right: 2px;
    width: 100%;
    margin-bottom: 10px;
  }
  .inner_trail_list .trail_post {
    margin-left: 20px;
    width: calc(100% - 20px);
  }
  .inner_trail_list .trail_post::before {
    left: -25px;
  }
  .inner_trail_list .trail_left {
    width: 100%;
  }
}
@media (max-width: 360px) {
  .inner_trail_list .trail_left {
    width: 100%;
  }
}

/*--------------------- subscribe page design css ----------------*/
.subscribe_padding {
  padding: 0px;
}
.merchant_subscribe {
  background-image: url(./assets/images/subscribe_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #fff;
}

.inside_merchant {
  padding: 40px 0px;
}

.merchant_subscribe .about_thruhike h3 {
  font-size: 50px;
}

.merchant_subscribe .about_thruhike p {
  font-size: 25px;
}

.inside_merchant.about_thruhike > div {
  width: 100%;
}

.about_thruhike h3 span.green_color {
  color: #008b2a;
}

.merchant_img {
  text-align: end;
}

.merchant_img img {
  width: 400px;
  margin: 0 auto;
}
.middle_box_section {
  background: rgba(3, 159, 56, 0.7);
  padding: 50px 10px;
}
.middle_box_section .inside_box {
  padding: 20px;
  height: 100%;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #fff;
  box-shadow: 0px 8px 20px 0px rgba(7, 75, 30, 0.2);
  position: relative;
  transition: 0.4s all ease-in-out;
}
.middle_box_section .inside_box::before {
  content: "";
  position: absolute;
  color: rgba(0, 139, 42, 0.15);
  font-size: 70px;
  font-weight: 600;
  bottom: 20px;
  left: 40px;
  line-height: normal;
  transition: 0.4s all ease-in-out;
}

.middle_box_section .inside_box.box_1::before {
  content: "Marketing";
}

.middle_box_section .inside_box.box_2::before {
  content: "Industry Insight";
}

.middle_box_section .inside_box.box_3::before {
  content: "Customer Experience";
}

.middle_box_section .inside_box img.icon_img {
  width: 50px;
  position: relative;
  top: 20px;
  left: 20px;
  transition: 0.4s all ease-in-out;
}
.middle_box_section .inside_box h4 {
  font-size: 24px;
  font-weight: 600;
  color: #008b2a;
  margin: 20px 0px;
  transition: 0.4s all ease-in-out;
}
.middle_box_section .inside_box p,
.middle_box_section .inside_box ul li {
  color: #374149;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
}

.middle_box_section .inside_box ul {
  list-style: none;
}

.middle_box_section .inside_box ul li {
  display: flex;
  align-items: baseline;
}

.middle_box_section .inside_box ul li img {
  margin-right: 10px;
}

.middle_box_section .inside_box p.strong {
  font-weight: 600;
}

.middle_box_section .inside_box .description_content,
.middle_box_section .inside_box h4 {
  opacity: 0;
  transition: 0.4s all ease-in-out;
}
.middle_box_section .inside_box:hover img {
  top: 0px;
  left: 0px;
  transition: 0.4s all ease-in-out;
}
.middle_box_section .inside_box:hover .description_content {
  opacity: 1;
  transition: 0.4s all ease-in-out;
}
.middle_box_section .inside_box:hover::before {
  font-size: 24px;
  position: absolute;
  bottom: 285px;
  left: 20px;
  color: #008b2a;
  transition: 0.4s all ease-in-out;
}

.vendor_subscription {
  margin: 100px 0px 100px 0px;
}
.vendor_subscription h3 {
  color: #231f20;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
}
.inside_vsubscribe {
  padding: 30px;
  border-radius: 20px;
  border: 1px solid #c7c7c7;
  background: #fff;
  height: 100%;
}
.inside_vsubscribe .most_popular_btn {
  padding: 8px 15px;
  border-radius: 5px;
  background: #d8f0df;
  color: #231f20;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}
.inside_vsubscribe h4 {
  margin-top: 30px;
  margin-bottom: 15px;
  color: #231f20;
  font-size: 26px;
  font-weight: 600;
}
.inside_vsubscribe h5 {
  color: #374149;
  font-size: 18px;
  font-weight: 500;
  height: 40px;
  overflow: hidden;
}
.inside_vsubscribe h2 {
  margin: 30px 0px;
  color: #231f20;
  font-size: 50px;
  font-weight: 600;
}
.inside_vsubscribe h2 span {
  color: rgba(35, 31, 32, 0.75);
  font-size: 24px;
  font-weight: 500;
}
.inside_vsubscribe .btn_save.btn_outline {
  border: 1px solid #008b2a;
  background: #fff;
  margin: 0px;
  color: #008b2a;
  width: 100%;
  font-size: 16px;
}
.inside_vsubscribe .btn_save.btn_outline:hover {
  background: #008b2a;
  color: #fff;
}
.inside_vsubscribe .description_content h6 {
  color: #231f20;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.inside_vsubscribe .description_content ul {
  list-style: none;
}

.inside_vsubscribe .description_content ul li {
  color: #374149;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.inside_vsubscribe .description_content ul li img {
  margin-right: 10px;
}

.inside_vsubscribe:hover {
  border: 1px solid #008b2a;
  background: #eef4f0;
}

.inside_vsubscribe:hover .most_popular_btn {
  background: #fff;
}

.inside_vsubscribe:hover .btn_save.btn_outline {
  background: #008b2a;
  color: #fff;
}

.inside_vsubscribe:hover .description_content h6 {
  font-weight: 500;
}

.inside_vsubscribe:hover .description_content ul li {
  color: #66737f;
}

@media (max-width: 1200px) {
  .middle_box_section .inside_box::before {
    font-size: 60px;
  }
  .middle_box_section .inside_box:hover::before {
    bottom: 309px;
  }
}
@media (max-width: 1152px) {
  .middle_box_section .inside_box:hover::before {
    bottom: 323px;
  }
}
@media (max-width: 1028px) {
  .merchant_subscribe .about_thruhike h3 {
    font-size: 40px;
  }

  .merchant_subscribe .about_thruhike p {
    font-size: 20px;
  }

  .middle_box_section .inside_box::before {
    font-size: 50px;
  }

  .middle_box_section .inside_box:hover::before {
    bottom: 357px;
  }

  .inside_vsubscribe {
    padding: 20px;
  }

  .inside_vsubscribe h4 {
    font-size: 20px;
    margin-top: 20px;
  }

  .inside_vsubscribe h5 {
    font-size: 16px;
  }

  .inside_vsubscribe h2 {
    font-size: 40px;
    margin: 20px 0px;
  }
  .inside_vsubscribe hr {
    margin: 30px 0px !important;
  }
}

@media (max-width: 980px) {
  .middle_box_section .inside_box,
  .vendor_subscription .inside_vsubscribe {
    width: 500px;
    margin: 0px auto;
  }
  .middle_box_section .col-md-12.col-lg-4,
  .vendor_subscription .col-md-12.col-lg-4 {
    margin-bottom: 20px;
  }

  .middle_box_section .inside_box:hover::before {
    bottom: 237px;
  }

  .vendor_subscription {
    margin: 40px 0px 0px 0px;
  }
}
@media (max-width: 767px) {
  .merchant_img {
    text-align: center;
  }
  .inside_merchant.about_thruhike {
    padding: 40px 0px;
  }
}
@media (max-width: 600px) {
  .merchant_subscribe .about_thruhike h3 {
    font-size: 30px;
  }
  .vendor_subscription h3 {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .merchant_subscribe .about_thruhike p {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .middle_box_section .inside_box,
  .vendor_subscription .inside_vsubscribe {
    width: 100%;
    margin: 0px auto;
  }
  .middle_box_section .inside_box {
    height: 330px;
    overflow: hidden;
  }

  .middle_box_section .inside_box:hover::before {
    bottom: 212px;
  }

  .middle_box_section .inside_box p,
  .middle_box_section .inside_box ul li {
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 5px;
  }
}
@media (max-width: 414px) {
  .merchant_subscribe .about_thruhike p {
    font-size: 16px;
  }

  .middle_box_section .inside_box {
    height: 380px;
  }

  .middle_box_section .inside_box:hover::before {
    bottom: 262px;
  }
}
@media (max-width: 360px) {
  .vendor_subscription h3 {
    font-size: 24px;
  }
  .middle_box_section .inside_box::before {
    font-size: 35px;
    left: 30px;
  }
  .middle_box_section .inside_box img.icon_img {
    width: 35px;
    top: 10px;
    left: 10px;
  }
  .middle_box_section .inside_box h4 {
    margin: 10px 0px;
    font-size: 20px;
  }

  .middle_box_section .inside_box {
    height: 340px;
  }

  .middle_box_section .inside_box:hover::before {
    font-size: 20px;
    bottom: 251px;
  }
  .middle_box_section .inside_box:hover img {
    top: 0px;
    left: 0px;
  }
}
@media (max-width: 320px) {
  .middle_box_section .inside_box::before {
    font-size: 40px;
  }

  .middle_box_section .inside_box {
    height: 350px;
  }

  .middle_box_section .inside_box:hover::before {
    bottom: 261px;
  }
}

/* ---------------------/- */
.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.item {
  background-color: #ececec;
  padding: 8px;
  margin: 4px;
  user-select: none;
}

.item:hover {
  background-color: #dcdcdc;
}
