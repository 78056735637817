@media (max-width: 1600px) {}

@media (max-width: 1440px) {}

@media (max-width: 1366px) {
  .subscriptions_tab .col-lg-4.text-center {
    width: 40%;
  }
}
@media (max-width: 1200px) {
  .inside_righttrail {
    max-width: 380px;
  }
  .trailslider .trail_mainimg {
    width: 180px;
    height: 180px;
  }
  .trailslider .trail_mainimg .multiple_img {
    width: 90px;
    height: 90px;
  }

  .trail_post_img {
    height: 200px;
  }

  .trail_detail_list.trailslider .trail_mainimg {
    width: 156px;
    height: 156px;
  }
  .trail_detail_list.trailslider .trail_mainimg .multiple_img {
    width: 78px;
    height: 78px;
  }

  .trail_post {
    height: 430px;
  }

  .trail_post_1 {
    height: 250px !important;
  }

  .about_us_content .img_position1 {
    margin-left: -60px;
  }
}

@media (max-width: 1152px) {
  .inside_righttrail {
    max-width: 370px;
  }
  .trailslider .trail_mainimg {
    width: 160px;
    height: 160px;
  }
  .trailslider .trail_mainimg .multiple_img {
    width: 80px;
    height: 80px;
  }
  .trail_detail_list.trailslider .trail_mainimg {
    width: 148px;
    height: 148px;
  }
  .trail_detail_list.trailslider .trail_mainimg .multiple_img {
    width: 74px;
    height: 74px;
  }

  .subscription_plan ul li {
    font-size: 16px;
  }

  .trail_post_user h4 a,
  .trail_post_user h4 {
    font-size: 14px;
  }
}

@media (max-width: 1028px) {
  .inside_righttrail {
    max-width: 250px;
    text-align: center;
  }
  .trailslider .trail_mainimg {
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }
  .trailslider .trail_mainimg .multiple_img {
    width: 100px;
    height: 100px;
  }
  .trailslider .hz_traillist h5 {
    display: inline-block;
    padding: 0px;
    width: 100%;
  }

  .owl-carousel .owl-nav button.owl-prev {
    float: left;
  }

  .owl-carousel .owl-dots {
    bottom: -18px;
  }

  .trail_mainimg {
    margin-right: 15px;
  }

  .hz_traillist h5 {
    width: calc(100% - 100px);
    padding-right: 10px;
  }

  .trail_detail_list.trailslider .col-lg-2 {
    width: 33%;
  }

  .trail_detail_list.trailslider .hz_traillist {
    text-align: center;
  }

  .trail_detail_list.trailslider .trail_mainimg {
    width: 200px;
    height: 200px;
  }
  .trail_detail_list.trailslider .trail_mainimg .multiple_img {
    width: 100px;
    height: 100px;
  }

  .your_favriout .trail_post_img {
    height: auto;
  }

  .subscriptions_tab .col-lg-4.text-center {
    width: 45%;
  }

  .payment .btn_save {
    padding: 12px 15px;
  }

  .detail_images .trail_post_img {
    height: auto;
    width: 480px;
  }

  .detail_images .side_smallimg .trail_post_img {
    height: auto;
    margin-bottom: 10px;
    width: 240px;
  }

  .side_smallimg .trail_post_img.video_icon span {
    left: 20%;
    top: 39%;
  }

  .subscription_plan ul li {
    font-size: 15px;
  }

  .side_smallimg .responsive_img {
    padding: 0px 5px;
    width: 250px;
  }

  .side_smallimg>.row {
    margin: 0px;
  }

  .trail_post_img img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 980px) {
  .navbar {
    height: auto;
    padding: 18px 0;
  }

  .navbar-toggler {
    background: #f2f2f7;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-collapse {
    display: flex;
    justify-content: center;
  }

  .profile_menu {
    flex-direction: row;
  }

  .trail_post_img {
    height: auto;
  }

  .inside_righttrail {
    max-width: 300px;
  }

  .trailslider .trail_mainimg {
    width: 160px;
    height: 160px;
  }
  .trailslider .trail_mainimg .multiple_img {
    width: 80px;
    height: 80px;
  }
  .navbar-collapse {
    position: absolute;
    top: 76px;
    width: 100%;
    background: rgb(255, 255, 255);
    left: 0px;
    padding-bottom: 20px;
    z-index: 1;
  }

  ul.navbar-nav .dropdown-menu {
    position: absolute;
  }

  ul.navbar-nav .dropdown-menu.location_drop {
    left: 0 !important;
  }

  /* .your_favriout .trail_post_img {
    height: 250px;
  } */
  .subscription_plan ul li {
    font-size: 15px;
  }

  .subscription_plan .quater_selection {
    padding: 15px;
  }

  .subscription_plan .quater_selection h6 {
    font-size: 22px;
  }

  .trail_post {
    height: auto;
  }

  .tag_list.bottom_fixed {
    position: relative;
    width: 100%;
    bottom: auto;
  }

  .about_us_content .img_position1 {
    margin-left: -20%;
  }

  .side_smallimg .responsive_img {
    /* padding: 15px 10px; */
    /* width: 310px; */
  }

}

@media (max-width: 820px) {

  /* .your_favriout .trail_post_img {
    height: 200px;
  } */
  /* .subscriptions_tab .col-lg-3 {
    width: 45%;
  } */
  .subscriptions_tab .col-lg-4.text-center {
    width: 60%;
  }

  .trail_detail .trail_post_user h4 {
    font-size: 22px;
  }

  .trail_detail {
    display: block !important;
  }

  .trail_detail .trail_tag_like {
    width: 100%;
    display: inline-block;
    text-align: right;
    margin-top: 10px;
  }

  .trail_detail .trail_tag_like .social_icon {
    font-size: 14px;
  }

  /* .detail_images .trail_post_img {
    height: 210px;
  } */
  .side_smallimg .trail_post_img.video_icon span {
    left: 11%;
  }
  /* .trail_post_img {
    height: 260px;
  } */

}
@media (max-width: 800px) {
  .inside_righttrail {
    max-width: 250px;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .inside_righttrail {
    max-width: 250px;
    text-align: center;
  }

  .side_smallimg .trail_post_img.video_icon span {
    left: 6%;
  }

  .trail_description .trail_post_user ul li a {
    font-size: 16px;
    margin-right: 13px;
  }

  .trail_post {
    height: 435px;
  }

  .tag_list.bottom_fixed {
    position: absolute;
    bottom: 8px;
    width: 94%;
  }

}
@media (max-width: 767px) {
  .rightside_trail {
    display: none;
  }

  .inside_righttrail {
    position: relative;
  }

  /* .trail_post_img {
    height: 190px;
  } */
  .inside_righttrail.fixed {
    top: 0;
    max-width: 100%;
  }
  .trailslider .trail_mainimg {
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }
  .trailslider .trail_mainimg .multiple_img {
    width: 100px;
    height: 100px;
  }
  .footer {
    position: relative;
    float: left;
    width: 100%;
  }
  .main_content {
    margin-bottom: 30px;
    padding-bottom: 20px;
  }
  .position_fixed {
    position: relative;
    bottom: 0;
    margin-top: 20px;
  }
  .addvideo_photo {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .addvideo_photo .video_play {
    left: 26px;
    top: 26px;
  }

  .subscription_plan {
    min-height: 370px;
  }

  /* .detail_images .trail_post_img {
    height: 400px;
  } */
  .side_smallimg .trail_post_img.video_icon span {
    left: 30%;
    top: 46%;
  }

  .all_images .heading_title {
    float: none !important;
  }

  .about_us_content .img_position1 {
    margin-left: 0;
  }

  .about_us_content .img_position2 {
    margin-top: 40px;
  }
}

@media (max-width: 736px) {}

@media (max-width: 667px) {

  .profile_menu li a,
  ul.profile_menu li.dropdown a {
    margin-left: 5px;
    padding: 10px 10px !important;
  }

  .footer_menu li a {
    padding: 0 5px;
  }

  .trail_detail_list.trailslider .trail_mainimg {
    width: 174px;
    height: 174px;
  }
  .trail_detail_list.trailslider .trail_mainimg .multiple_img {
    width: 87px;
    height: 87px;
  }
  /* .your_favriout .trail_post_img {
    height: 160px;
  } */
  /* .subscriptions_tab .col-lg-3 {
    width: 60%;
  } */
  .subscriptions_tab .col-lg-4.text-center {
    width: 100%;
  }
  /* .detail_images .trail_post_img {
    height: 350px;
  } */
  .side_smallimg .trail_post_img.video_icon span {
    left: 26%;
    top: 36%;
  }
  .trail_post {
    height: 435px;
  }

}

@media (max-width: 640px) {

  /* .trail_post_img {
    height: 160px;
  } */
  .trail_detail_list.trailslider .trail_mainimg {
    width: 168px;
    height: 168px;
  }
  .trail_detail_list.trailslider .trail_mainimg .multiple_img {
    width: 83px;
    height: 83px;
  }
  .trail_post {
    height: 430px;
  }

}
@media (max-width: 600px) {
  .trail_detail_list.trailslider .col-lg-2 {
    width: 50%;
  }
  .trail_post {
    height: 420px;
  }
}
@media (max-width: 568px) {
  .trail_mainimg {
    width: 60px;
    height: 60px;
  }
  .multiple_img {
    width: 30px;
    height: 30px;
  }

  .hz_traillist h5 {
    width: calc(100% - 75px);
  }

  /* .trail_post_img {
    height: auto;
  } */
  .navbar-collapse {
    position: relative;
    top: 0;
    width: 100%;
    background: rgb(255, 255, 255);
    left: auto;
    padding-bottom: 0;
    display: block;
  }
  ul.navbar-nav .dropdown-menu.location_drop {
    left: 0 !important;
    position: relative;
    width: 100%;
    margin: 0 0px 15px;
  }

  .search_box {
    margin-bottom: 10px;
  }

  .profile_menu {
    float: right;
  }

  .footer .d-flex {
    display: block !important;
    text-align: center;
  }
  .footer_menu:first-of-type {
    float: left;
    margin-top: 6px !important;
  }
  .footer_menu:last-child {
    float: right;
    margin-top: 6px !important;
  }

  .post_description {
    height: auto;
  }

  /* .your_favriout .trail_post_img {
    height: auto;
  } */
  .detail_images .trail_post_img {
    height: auto;
  }
  .side_smallimg .trail_post_img.video_icon span {
    left: 38%;
    top: 48%;
  }

  .trail_post {
    height: auto;
  }

  .tag_list.bottom_fixed {
    position: relative;
    width: 100%;
    bottom: auto;
  }

}
@media (max-width: 536px) {
  .trail_detail .trail_tag_like .social_icon {
    font-size: 13px;
  }
  .trail_detail .trail_tag_like .social_icon img {
    margin-right: 3px;
    max-height: 16px;
  }
  .all_images .column {
    flex: 100%;
    max-width: 100%;
  }
}
@media (max-width: 480px) {
  .addvideo_photo {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .addvideo_photo .video_play {
    width: 20px;
    height: 20px;
    left: 20px;
    top: 20px;
  }
  .inside_tab {
    display: inline-flex;
    width: 100%;
  }

  .trail_detail .trail_tag_like .social_icon {
    margin: 0px;
  }

  .modal-body .hz_traillist h5 {
    width: calc(100% - 80px);
  }

  .about_us_content .img_position1,
  .about_us_content .img_position2 {
    width: 100%;
    height: auto
  }

  .detail_images .trail_post_img {
    width: 100%;
  }
}

@media (max-width: 414px) {
  .trail_mainimg {
    width: 50px;
    height: 50px;
    margin-right: 7px;
  }
  .multiple_img {
    width: 25px;
    height: 25px;
  }

  .hz_traillist {
    margin-bottom: 10px;
  }

  .hz_traillist h5 {
    width: calc(100% - 65px);
    font-size: 13px;
  }
  .heading_title,
  .heading_title span {
    float: left;
    width: 100%;
  }
  .heading_title a.backarrow.rename {
    float: left;
    padding: 10px;
    margin: 0px;
    margin-top: 0px;
  }
  .trail_detail_list.trailslider .trail_mainimg {
    width: 160px;
    height: 160px;
  }
  .trail_detail_list.trailslider .trail_mainimg .multiple_img {
    width: 80px;
    height: 80px;
  }
  .btn_cancel,
  .btn_save {
    padding: 12px 20px;
    width: 40%;
  }
  /* .subscriptions_tab .col-lg-3 {
    width: 90%;
  } */
  .inside_tab .nav-item {
    flex-basis: auto;
  }

  .featuredon h5 p {
    width: 85%;
  }

  .featuredon h5 {
    display: inline-block;
  }

  .trail_description .user_img {
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }
  .trail_description .trail_post_user ul li {
    display: block;
    margin: 0;
  }

  .trail_detail .trail_tag_like .social_icon {
    display: none;
  }

  .trail_detail .trail_tag_like .navbar-nav .social_icon {
    display: block;
  }

  .side_smallimg .trail_post_img.video_icon span {
    left: 33%;
    top: 41%;
  }
}
@media (max-width: 393px) {
  .trail_detail_list.trailslider .trail_mainimg {
    width: 140px;
    height: 140px;
  }
  .trail_detail_list.trailslider .trail_mainimg .multiple_img {
    width: 70px;
    height: 70px;
  }
}
@media (max-width: 375px) {
  .trail_detail_list.trailslider .col-lg-2 {
    width: 100%;
  }
  .trail_detail_list.trailslider .trail_mainimg {
    width: 200px;
    height: 200px;
  }
  .trail_detail_list.trailslider .trail_mainimg .multiple_img {
    width: 100px;
    height: 100px;
  }
  .addvideo_photo {
    width: 57px;
    height: 57px;
  }
  .detail_images .trail_post_img img.video_play {
    left: 45%;
    top: 41%;
  }

}

@media (max-width: 360px) {
  .footer_menu:first-of-type {
    float: none;
    width: 100%;
    margin-top: 6px !important;
  }
  .footer_menu:last-child {
    float: none;
    width: 100%;
    margin-top: 6px !important;
  }
  .addvideo_photo {
    width: 70px;
    height: 70px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .addvideo_photo .video_play {
    width: 25px;
    height: 25px;
    left: 23px;
    top: 23px;
  }
}
@media (max-width: 320px) {
  .addvideo_photo {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .addvideo_photo .video_play {
    width: 20px;
    height: 20px;
    left: 20px;
    top: 20px;
  }
  .subscriptions_tab .heading_title {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .inside_tab .nav-item {
    font-size: 14px;
  }

  .side_smallimg .trail_post_img.video_icon span {
    left: 26%;
    top: 41%;
  }

  .detail_images .side_smallimg .trail_post_img {
    width: 100%;
  }
}
