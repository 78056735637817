* {
  margin: 0;
  padding: 0;
  text-indent: 0;
}
h1 {
  color: black;
  /* font-family: "Times New Roman", serif; */
  /* font-style: normal; */
  /* font-weight: bold; */
  text-decoration: none;
  font-size: 15pt;
}
h2 {
  color: black;
  /* font-family: "Times New Roman", serif; */
  /* font-style: normal; */
  /* font-weight: bold; */
  text-decoration: none;
  font-size: 12.5pt;
}
.s1 {
  color: black;
  /* font-family: "Times New Roman", serif; */
  /* font-style: normal; */
  /* font-weight: bold; */
  text-decoration: none;
  font-size: 12.5pt;
}
.p,
p {
  color: black;
  /* font-family: "Times New Roman", serif; */
  /* font-style: normal; */
  /* font-weight: normal; */
  text-decoration: none;
  font-size: 12.5pt;
  margin: 0pt;
}
.a,
a {
  color: black;
  /* font-family: "Times New Roman", serif; */
  /* font-style: normal; */
  /* font-weight: normal; */
  text-decoration: none;
  font-size: 15pt;
}
